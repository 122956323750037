import { requestType } from "~/config/enum.config";
var SERVICE = "business-service";
var CONTROLLER = "caseFollowRecordController";
export default {
    /**
     * 添加调记
     */
    addCaseFollowRecord: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "addCaseFollowRecord",
        type: requestType.Post,
    },
    /**
     * 添加查找记录
     */
    addFindRecord: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "addFindRecord",
        type: requestType.Post,
    },
    /**
     * 添加其他调记
     */
    addOtherRecord: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "addOtherRecord",
        type: requestType.Post,
    },
    /**
     * 调记查询
     */
    getAllCaseFollowRecord: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "getAllCaseFollowRecord",
        type: requestType.Get,
    },
    /**
     * 查找记录查询
     */
    getAllFindRecord: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "getAllFindRecord",
        type: requestType.Get,
    },
    /**
     * 其他调记查询
     */
    getAllOtherRecord: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "getAllOtherRecord",
        type: requestType.Get,
    },
    /**
     * 删除调记
     */
    deleteCaseFollowRecord: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "deleteCaseFollowRecord",
        type: requestType.Delete,
    },
    /**
     * 修改调记
     */
    updateCaseFollowRecord: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "updateCaseFollowRecord",
        type: requestType.Post,
    },
    /**
     * 添加标注
     */
    addTag: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "addTag",
        type: requestType.Post,
    },
    /**
     * 工单回复
     */
    replyOtherRecord: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "replyOtherRecord",
        type: requestType.Post,
    },
    /**
     * 查询标注
     */
    getAllTag: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "getAllTag",
        type: requestType.Get,
    },
    /**
     * 删除标注
     */
    deleteTag: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "deleteTag",
        type: requestType.Delete,
    },
    /**
     * 删除标注
     */
    getCommentByType: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "getCommentByType",
        type: requestType.Get,
    },
    /**
     * 敏感词校验
     */
    checkString: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "checkString",
        type: requestType.Post,
    },
    /**
     * 查询委前调记
     */
    getAllPreRecord: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "getAllPreRecord",
        type: requestType.Get,
    },
    /**
     * 获取共债跟进记录
     */
    getDebtFollowRecord: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "getDebtFollowRecord",
        type: requestType.Get,
    },
    /**
     * 导出
     */
    export: {
        service: SERVICE,
        controller: CONTROLLER,
        action: "export",
        type: requestType.Get,
    },
};
